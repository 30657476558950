import type { DataArrayPartial, Option } from "slim-select/dist/store";
import type { IMarriottLocation } from "../@types";

export async function fetchMarshaCodes(selectField: HTMLSelectElement) {
    const response = await fetch(`${formAjax.ajaxUrl}?action=get_locations`);

    if (!response.ok) {
        throw new Error("Couldn't fetch MARSHA Codes");
    }

    const locations: IMarriottLocation[] = await response.json();

    const { value } = selectField.dataset;

    const selectData: DataArrayPartial = locations.map((location) => {
        return {
            text: `${location.locationNumber} - ${location.locationName}`,
            value: location.locationNumber,
            selected: location.locationNumber === value,
            data: {
                postalCode: location.postalCode,
            },
        };
    });

    return selectData;
}

export function setAddress(option: Option) {
    const { postalCode } = option.data;
    const postalCodeInput = document.querySelector<HTMLInputElement>(
        "input[name='input_27']",
    );

    if (postalCodeInput) {
        postalCodeInput.value = postalCode;
    }
}
