import SlimSelect from "slim-select";
import { fetchMarshaCodes, setAddress } from "./modules/location";

async function createMarshaSelect() {
    const selectField =
        document.querySelector<HTMLSelectElement>(".marsha-codes");

    if (!selectField) {
        throw new Error("MARSHA select input doesn't exist");
    }

    const marshaCodes = await fetchMarshaCodes(selectField);

    new SlimSelect({
        select: ".marsha-codes",
        data: [
            {
                placeholder: true,
                text: "Select your MARSHA Code",
                disabled: true,
            },
            ...marshaCodes,
        ],
        events: {
            afterChange: (selected) => {
                setAddress(selected[0]);
            },
        },
    });
}

function formatAmounts(event: Event) {
    const input = event.target;

    if (!(input instanceof HTMLInputElement)) {
        return;
    }

    const value = parseFloat(input.value.replaceAll(",", ""));

    if (isNaN(value)) {
        return;
    }

    const formattedValue = new Intl.NumberFormat("en-US", {
        maximumFractionDigits: 2,
    }).format(value);

    input.value = formattedValue;
}

// eslint-disable-next-line @typescript-eslint/no-misused-promises
jQuery(document).on("gform_post_render", async () => {
    const donationList = document.querySelector(".gfield_list_groups");

    donationList?.addEventListener("change", formatAmounts);

    await createMarshaSelect();
});
